import React from 'react';

import Layout from '../components/Layout';

import Scroll from '../components/Scroll';

import pic1 from '../assets/images/volunteer_6_1920.jpg';
import pic2 from '../assets/images/projects_6_1280.png';
import pic3 from '../assets/images/associations_2_1920.jpg';
import config from '../../config';
const IndexPage = () => (
  <Layout>
  <section id="banner">
    <div className="inner">
      <h2 className= "who">{config.heading}</h2>
      
      <p className="who">Asociaciones</p>

      <p>{config.subHeading}</p>
      <ul className="actions special">
        <li>
          <a href="/#one" className="button secondary">
            Voluntarios
          </a>
        </li>
        <li>
          <Scroll type="id" element="one">
            <a href="/Entities/#one" className="button secondary">
              Asociaciones
            </a>
          </Scroll>
        </li>
      </ul>
    </div>
    <Scroll type="id" element="one">
      <a href="#one" className="more">
        Saber más
      </a>
    </Scroll>
  </section>

  <section id="one" className="wrapper style6 special">
    <div className="inner">
      <header className="major">
        <h2>
          Un puente entre entidades y hacedores 
        </h2>
        <p>
          Una plataforma digital que conecta a entidades sin 
          ánimo de lucro con todos aquellos que quieren respaldarlas
        </p>
      </header>
      <ul className="icons minor">
      <li>
          <p>
            <Scroll type="id" element="volunteering">
              <a href="#volunteering">Voluntariado</a>
            </Scroll>
          </p>
          <Scroll type="id" element="volunteering">
            <a href="#volunteering" className="icon fa-heart major style1"></a>
          </Scroll>
        </li>
        <li>
          <p>
            <Scroll type="id" element="associations">
              <a href="#associations">Asociaciones</a>
            </Scroll>
          </p>
          <Scroll type="id" element="associations">
            <a href="#associations" className="icon fa-building major style4"></a>
          </Scroll>
        </li>
        <li>
          <p>
            <Scroll type="id" element="projects">
              <a href="#projects">Proyectos</a>
            </Scroll>
          </p>
          <Scroll type="id" element="projects">
            <a href="#projects" className="icon fa-compass major style2"></a>
          </Scroll>
        </li>
      </ul>
    </div>
  </section>

  <section id="two" className="wrapper alt style2">
    <section id="volunteering" className="spotlight reverse">
      <div className="image">
        <img src={pic1} alt="" />
      </div>
      <div className="content">
        <div className="sub_content">
          <h2>
            Voluntariado
          </h2>
          <p>
            Acceded a una bolsa de voluntarios donde poder notificar de vuestras novedades
            o proyectos de financiación a todos aquellos voluntarios que quieran o que os estén 
            siguiendo. Haced llamadas a proyectos de voluntariado de forma general o específica,
            filtrando por el tipo de habilidades que necesitáis que tengan para el proyecto.
          </p>
          <Scroll type="id" element="three">
              <a href="#three" className="icon solid fa-arrow-down circle right"></a>
          </Scroll>
        </div>
      </div>
    </section>

    <section id="associations" className="spotlight reverse">
      <div className="image">
        <img src={pic3} alt="" />
      </div>
      <div className="content">
        <div className="sub_content">
          <h2>
            Asociaciones
          </h2>
          <p>
            Un espacio con las herramientas para tener vuestra landing page en questión de minutos y 
            poder actulizar sus contenidos de forma senzilla. Con funcionalidades para configurar y 
            recibir donativos sin pasar por trámites tediosos. 
          </p>
          <Scroll type="id" element="projects">
              <a href="#projects" className="icon solid fa-arrow-down circle left"></a>
          </Scroll>
        </div>
      </div>
    </section>

    <section id="projects" className="spotlight reverse">
      <div className="image">
        <img src={pic2} alt="" />
      </div>
      <div className="content">
        <div className="sub_content">
          <h2>
            Proyectos
          </h2>
          <p>
            Financiad vuestros objetivos creando proyectos, decid su finalidad
            y poned la cantidad que necesitáis. Para que sea más fácil que se
            financien, explicad que está pasando mientras se están financiando y
            las experiencias una vez finalizados. ¡Cuanta más información, más gustará!
          </p>
          <Scroll type="id" element="volunteering">
              <a href="#volunteering" className="icon solid fa-arrow-down circle right"></a>
          </Scroll>
        </div>
      </div>
    </section>
  </section>

  <section id="three" className="wrapper style1 special">
    <div className="inner">
      <header className="major">
        <h2>Nuestra Plataforma</h2>
        <p>
          Pon tu esfuerzo en lo que realmente importa, nosotros nos encargamos de lo demás.
        </p>
      </header>
      <ul className="features">
        <li className="icon solid fa-compress-arrows-alt before_style1">
          <h3>Todo en uno</h3>
          <p>
          Mapa de las asociaciones, vuestra información, que proyectos tenéis activos,
          voluntariados y contacto con vosotros.
          </p>
        </li>
        <li className="icon solid fa-map-marked-alt before_style1">
          <h3>Mapa</h3>
          <p>
            Mediante un mapa interactivo, los voluntarios verán donde te encuentras y si estás 
            cerca de ellos, desde el mismo mapa verán cuáles son vuestras metas.
          </p>
        </li>
        <li className="icon solid fa-star-half-alt before_style1">
          <h3>Confianza</h3>
          <p>
            Los usuarios podrán hacer saber su experiencia con vosotros.
            Mejores valoraciones = más confianza, lo que implica más 
            voluntarios que os apoyen.
          </p>
        </li>
        <li className="icon solid fa-link before_style1">
          <h3>Conexión</h3>
          <p>
            Mediante accesos rápidos, los voluntarios podrán colaborar con vosotros 
            difundiendo lo que hacéis en sus redes sociales.
          </p>
        </li>
        <li className="icon fa-eye before_style1">
          <h3>Visibilidad</h3>
          <p>
            Cuanta más información deis, mejores valoraciones tendréis,
            más visibles seréis y más voluntarios os seguirán. Esto a 
            la vez hará que más voluntarios sepan de vosotros.
          </p>
        </li>
        <li className="icon fa-question-circle before_style1">
          <h3>Transacciones simples</h3>
          <p>
            Nuestra plataforma proporcionará a los voluntarios un sistema
            de donaciones simple pero efectiva para financiar vuestra asociación
            o proyectos. No os tendréis que preocupar como hacer las recaudaciones
            ni lidiar con sistemas de pago.
          </p>
        </li>
      </ul>
    </div>
  </section>

  <section id="cta" className="wrapper style4">
      <div className="inner">
        <header>
          <h2>Os avisamos cuando todo esté listo</h2>
          <p>
            Decidnos vuestro nombre e email y sed de los primeros en probar la plataforma
          </p>
        </header>
      </div>
      <div className="inner">
					<form method="post" action="#">
						<div className="row gtr-uniform">
							<div className="col-5 col-12-xsmall">
								<input type="text" name="demo-name" id="demo-name" defaultValue="" placeholder="Nombre" />
							</div>
							<div className="col-6 col-12-xsmall">
								<input type="email" name="demo-email" id="demo-email" defaultValue="" placeholder="Email" />
							</div>
						</div>
            <div className="col-12">
								<ul className="actions">
									<li><input type="submit" value="Enviar" className="primary" /></li>
								</ul>
						</div>
					</form>
        </div>
    </section>
  </Layout>
);

export default IndexPage;
